import { BackButton }  from "./button/BackButton";

function PageHeading({ onBack = null, pagePath }) {
 
  const backClasses = "bold flex gap-1 content-center";

  return (
    <div class="flex justify-between w-full ">
      <span class="flex">
        Virtual AR
        {pagePath && pagePath[0] !== undefined &&
          pagePath.map((onePath) => {
            if (onePath === undefined) {
              return;
            }
            return (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12.6 12L8.7 8.1q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375q0 .2-.062.375t-.213.325l-4.6 4.6q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7z"
                  />
                </svg>
                {onePath}{" "}
              </>
            );
          })}
      </span>
      {pagePath ? (
        <button className={backClasses} onClick={() => onBack()}>
          <BackButton />
        </button>
      ) : (
        <a href="/" class={backClasses}>
          <BackButton />
        </a>
      )}
    </div>
  );
}
export default PageHeading;
