
function Landing() {
  const pathName = "./images/hero/-img-1.png"
  console.log(pathName)
  return (
    <main className="relative w-full h-screen max-h-[605px] sm:max-h-[350px] xl:max-h-[545px] pb-2 bg-[rgba(253,195,1,1)]">
      <section className="w-full h-full max-h-[350px] xl:max-h-[600px] sm:hidden relative">
        <div className="w-full h-full bg-[#fcf8f5] absolute inset-0"></div>
        <div
          className=" h-full bg-cover bg-right absolute inset-0"
          style={{
            backgroundImage: 'url("./images/hero/hero-img-1.png")',
            zIndex : "111"
          }}
        ></div>
      </section>

      <div className="bg-transparent relative w-full h-full max-h-[350px] xl:max-h-[650px] flex">
        <div
          className="xl:w-3/6 md:w-4/6 w-5/6 absolute right-0 h-full bg-cover bg-right hidden sm:block"
          style={{
            backgroundImage: 'url("./images/hero/hero-img-1.png")',
            zIndex : "111"
        
        }}
        ></div>
        <div className="w-full h-full bg-[#fcf8f5] absolute"></div>
        <section className="overlay-slide absolute inset-0 flex items-center justify-center">
          <div className="text-white text-left px-10 xl:px-32">
            <article>
              <h2 className="xl:text-4xl font-bold md:mb-4 mt-5">
                Lorem Ipsum dolor sit amet
              </h2>
              <p class="xl:text-lg text-xs md:text-lg m-0">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Quisquam, veritatis vitae?
              </p>
            </article>
            <ul className="control-btn mt-3 py-1 md:py-2 xl:mt-12 flex-col md:flex xl:flex-col items-start gap-2 md:gap-6">
              <li>
                <a href="/preset">PRESET</a>
              </li>
              <li>
                <a href="/tryon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 17.5q1.875 0 3.188-1.312T16.5 13q0-1.875-1.312-3.187T12 8.5q-1.875 0-3.187 1.313T7.5 13q0 1.875 1.313 3.188T12 17.5m0-2q-1.05 0-1.775-.725T9.5 13q0-1.05.725-1.775T12 10.5q1.05 0 1.775.725T14.5 13q0 1.05-.725 1.775T12 15.5M4 21q-.825 0-1.412-.587T2 19V7q0-.825.588-1.412T4 5h3.15L8.4 3.65q.275-.3.663-.475T9.875 3h4.25q.425 0 .813.175t.662.475L16.85 5H20q.825 0 1.413.588T22 7v12q0 .825-.587 1.413T20 21zm0-2h16V7h-4.05l-1.825-2h-4.25L8.05 7H4zm8-6"
                    ></path>
                  </svg>
                  TRY ON
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div>

    </main>
  );
}
export default Landing;
