function Products({ currentProducts, selectProduct, productSelected }) {
  return (
    <section id="products" class="mt-16">
      {currentProducts.map((oneProduct, productID) => {
        const isSelected =
          oneProduct.productName === productSelected.productName;
          const productName = oneProduct.productName[0] + oneProduct.productName.substring(1,oneProduct.productName.length).toLowerCase()
          const nameDisplayed = oneProduct.productName[0]
        return (
          <div
          style={{color : isSelected && "rgba(190, 84, 153, 1)"}}
            class="one-product flex content-center"
            onClick={() => selectProduct(productID)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 15 15"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M2 7.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5"
                clip-rule="evenodd"
              />
            </svg>
            <div class="flex content-center justify-center">
              {productName}
            </div>
          </div>
        );
      })}
    </section>
  );
}

export default Products;
