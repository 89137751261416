import ResetButton from "./button/ResetButton";
import SplitButton from "./button/SplitButton";

const PhotoContainer = ({ imgUrl, isSectionSelected }) => {
  return (
    <div
      className=" photo-container relative 
  w-[225px] h-[300px] mt-[125px] 
    max-[767px]:w-64 max-[767px]:h-full
    max-[948px]:w-[140px] max-[950px]:h-[205px]"
    >
      <div id="reset-btn">
        <ResetButton />
      </div>
      <div className="photo-background absolute"></div>

      <img
        style={{zIndex : "-1"}}
        className="photo block w-full h-full"
        src={imgUrl}
        alt="Foto"
      />
      {!isSectionSelected && (
        <div class="w-full bg-[#986E10] absolute inset-0"></div>
      )}
      <div class="absolute inset-y-0 inset-x-0 w-full h-full flex ">
        {!isSectionSelected && (
          <button
            className="max-h-10 max-w-[150px] my-auto 
  border-2 border-white px-5 py-2 pt-[6px] hover:text-[#986E10] rounded-3xl flex content-center bg-transparent bg-opacity-100 text-white hover:bg-white mx-auto  duration-100 ease gap-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 17.5q1.875 0 3.188-1.312T16.5 13q0-1.875-1.312-3.187T12 8.5q-1.875 0-3.187 1.313T7.5 13q0 1.875 1.313 3.188T12 17.5m0-2q-1.05 0-1.775-.725T9.5 13q0-1.05.725-1.775T12 10.5q1.05 0 1.775.725T14.5 13q0 1.05-.725 1.775T12 15.5M4 21q-.825 0-1.412-.587T2 19V7q0-.825.588-1.412T4 5h3.15L8.4 3.65q.275-.3.663-.475T9.875 3h4.25q.425 0 .813.175t.662.475L16.85 5H20q.825 0 1.413.588T22 7v12q0 .825-.587 1.413T20 21zm0-2h16V7h-4.05l-1.825-2h-4.25L8.05 7H4zm8-6"
              ></path>
            </svg>
            <span class="uppercase">try on</span>
          </button>
        )}
      </div>
      <div id="split-btn">
        <SplitButton text={"Split view"} />
      </div>
    </div>
  );
};
export default PhotoContainer;
