//import PageHeading from "../components/PageHeading"; // Assuming you have a PageHeading component
import { useState } from "react";
import "../css/preset.css"
import PageHeading from "../components/PageHeading";
function Preset() {
  const preset = [
    {
      presetName: "spiaggia",
      sliderIMG: "./images/preset/preset-img-1.png",
      mainIMG: "./images/preset/happy-hour.png",
      items: [],
    },
    {
      presetName: "tutti i giorni",
      sliderIMG: "./images/preset/preset-img-2.png",
      mainIMG: "./images/preset/happy-hour.png",
    },
    {
      presetName: "happy hour",
      sliderIMG: "./images/preset/preset-img-3.png",
      mainIMG: "./images/preset/happy-hour.png",
    },
    {
      presetName: "evento/serata",
      sliderIMG: "./images/preset/preset-img-4.png",
      mainIMG: "./images/preset/happy-hour.png",
    },
  ];

  const [currentPreset, setCurrentPreset] = useState();

  function handleSelectPreset(selectedPreset) {
    setCurrentPreset(selectedPreset);
  }

  return (
    <main id = "preset" >

      <section className="px-8 md:px-16">
      <PageHeading />
        <section className="relative">
          <div className="bg-vector"></div>
          <section className="img-container mt-10 flex max-w-full mx-auto relative ">
            {currentPreset !== undefined && (
              <img
                src={currentPreset.mainIMG}
                className={"absolute w-full h-full inset-0"}
              />
            )}
            {preset.map((onePreset) => (
              <img
                loading="lazy"
                className="flex-1"
                style={{ width: "25%" }}
                alt=""
                src={onePreset.sliderIMG}
                key={onePreset.presetName}
              />
            ))}
          </section>
          <div className="control-btn flex gap-2 md:gap-2 text-xs sm:text-sm md:text-sm flex-col sm:flex-row">
            {preset.map((onePreset) => {

              return (
                <button
                  
                className={`uppercase ${
                  currentPreset && onePreset.presetName === currentPreset.presetName && 'hidden md:block btn-selected'
                } `}
                
                  key={onePreset.presetName}
                  onClick={() => handleSelectPreset(onePreset)}
                >
                  {onePreset.presetName}
                </button>
              );
            })}
          </div>
        </section>
      </section>
    </main>
  );
}

export default Preset;
