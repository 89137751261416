import { SECTIONS } from "../util/product-sections";

const SectionControl = ({ currentSection, updateCurrentSectionState }) => {
  return (
    <>
      <h2 class="uppercase my-10 text-3xl text-purple bold text-center md:text-left">virtual try-on</h2>
      <section class="flex gap-4 xl:gap-14 justify-center md:justify-start">
        {SECTIONS.map((oneSection, idx) => {
          const imgURL =
            oneSection === currentSection
              ? oneSection + "-selected"
              : oneSection;
          return (
            <div 
            
            class="img-container col">
              <img
                src={`./images/${imgURL}.png`}
                alt={oneSection}
                style = {{
marginLeft : idx === 3 && "45px",
marginTop : idx === 0 && "10px"


                }}
                onclick={() =>
                  oneSection !== "preset" &&
                  updateCurrentSectionState(idx)
                }
              />
              <h3>{oneSection}</h3>
            </div>
          );
        })}
      </section>
    </>
  );
};
export default SectionControl;
