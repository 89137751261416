import './App.css';
import Landing from "./pages/Landing"
import Preset from "./pages/Preset"
import Tryon from "./pages/Tryon"
import './tailwind.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
function App() {
  return (
    <Router>
    <Routes>
        <Route exact path="/" element={<Landing/>} />
        <Route path="/preset" element={<Preset />} />
        <Route
            path="/tryon"
            element={<Tryon />}
        />

    </Routes>
</Router>
  );
}

export default App;
