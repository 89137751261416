import { useEffect, useState } from "react";
import  {SECTIONS} from "../util/product-sections.js";
import SectionControl from "../components/SectionControl.jsx";
import Products from "../components/Products.jsx";
import { getProductsBySection } from "../util/getProductBySections.js";
import PageHeading from "../components/PageHeading.jsx";
import Camera from "../components/Camera.jsx"
import "../css/tryon.css"


export default function Tryon() {
  const initialProducts = getProductsBySection(SECTIONS[0]);
  // State to manage the current section, current products, selected product and userActions
  const [currentSection, setCurrentSection] = useState();
  const [currentProducts, setCurrentProducts] = useState(initialProducts);
  const [productSelected, setProductSelected] = useState(initialProducts[0]);
  const [userActions, setUserActions] = useState([]);
  const logUserAction = (type, value) => {
    let newAction;
    if (type === "selectSection") {
      newAction = {
        section: value,
        product: productSelected,
      };
    } else if (type === "selectProduct") {
      newAction = {
        section: currentSection,
        product: value,
      };
    }
    setUserActions((prevActions) => [...prevActions, newAction]);
  };
  // Effect to update products when the section changes
  useEffect(() => {
    if (currentSection === undefined) {
      return;
    }
    // Get new products and select the first product
    const newProducts = getProductsBySection(currentSection);
    const newProductSelected = newProducts[0];

    setCurrentProducts(newProducts);
    setProductSelected(newProductSelected);
    console.log(newProducts, newProductSelected);
  }, [currentSection]);
  // Handles selecting a product
  function handleSelectProduct(index) {
    const newSelectedProduct = currentProducts[index];
    setProductSelected(newSelectedProduct);
    logUserAction("selectProduct", newSelectedProduct);
  }
  // Handles selecting a section
  function handleSelectSection(idx) {
    const newSection = SECTIONS[idx];
    setCurrentSection(newSection);
    logUserAction("selectSection", newSection);
  }

  function handleBack() {
    if (userActions.length === 0) {
      setCurrentSection(null);
      return;
    }
    const backSection = userActions[userActions.length - 1].section;
    const backProduct = userActions[userActions.length - 1].product;

    setCurrentSection(backSection);
    setProductSelected(backProduct);
    console.log(userActions.pop, "dasd");
    const newUserHistory = [...userActions];
    newUserHistory.pop();
    setUserActions(newUserHistory);
  }
  // Checks if a section is selected
  const isSectionSelected = currentSection;
  return (
    <main id="tryon" className="py-16 ">
      <PageHeading
        onBack={handleBack}
        userActions={userActions}
        pagePath={
          currentSection && [currentSection, productSelected.productName]
        }
      />

      <section className="flex relative flex-col md:flex-row ">
        <div className="bg-vector hidden md:block"></div>
        <section id="sections" className="w-full md:w-7/12">
          <SectionControl
            currentSection={currentSection}
            updateCurrentSectionState={handleSelectSection}
          />
          {currentSection ? (
            <>
              <Products
                currentProducts={currentProducts}
                selectProduct={handleSelectProduct}
                productSelected={productSelected}
              />

              <section className="flex justify-center content-center mt-10">
                <div
                  className="w-64 h-64 my-10 rounded-full"
                  style={{ backgroundColor: `${productSelected.hexColor}` }}
                ></div>
              </section>
            </>
          ) : (
            <p className="mt-6 md:mt-24 text-center md:text-start">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
              rhoncus viverra orci id porta. Nulla consequat cursus risus a
              pretium. Proin venenatis luctus sapien et ornare. Praesent luctus
              scelerisque libero, vitae pellentesque risus placerat nec. Aliquam
              a pulvinar nisi, vitae sollicitudin dolor.
            </p>
          )}
        </section>

        <aside className="md:ms-14 w-full md:w-5/12 flex flex-wrap justify-center px-30">
          <Camera
            imgUrl={"./images/tryonimage.png"}
            isSectionSelected={isSectionSelected}
          />
        </aside>
        <section id="products"></section>
      </section>
    </main>
  );
}
